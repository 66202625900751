<template>
  <modal name="modal" :classes="
    padding
      ? ['main-vue-modal', 'rounded-3xl']
      : ['no-padding', 'rounded-3xl']
  " :width="width" :height="height" >
    <header class="text-green-1002" :class="margin ? 'vue-modal-header' : 'no-margin'">
      <slot name="header"></slot>
    </header>
    <section class="text-green-1002" :class="margin ? 'vue-modal-body' : 'no-margin'">
      <div slot="top-right" style="position: absolute; top: 10px; right: 10px;">
        <button @click="$modal.hide('modal')">
          <i class="ri-close-fill text-black text-2xl"></i>
        </button>
      </div>
      <template>
        <div class="w-full flex justify-center px-4 my-10">
          <h1 class="text-2xl text-green-900 font-bold mb-1">
            Selecione o seu Sindicato Rural
          </h1>
        </div>
        <div class="w-full flex justify-center px-4 mb-5">
          <multiselect 
            v-model="value" 
            :options="options" 
            placeholder="Selecione o sindicato de acordo com sua localidade." 
            label="name" 
            track-by="name"
            @select="dispatchAction">
          </multiselect>
        </div>
        
      </template>
    </section>
    <div class="flex justify-center">
      <slot name="footer"></slot>
    </div>
  </modal>
</template>

<script>
import axios from '@/utils/axios';


export default {
  name: 'ModalLayout',

  data() {
    return {
      value: '',
      options: [],
      width: 'auto',
      height: '80%'
    };
  },

  methods: {
    dispatchAction(syndicate){
      window.open(syndicate.url, '_blank')
    },

    show() {
      this.$modal.show('modal');
    },
    hide() {
      this.$modal.hide('modal');
    },

    setModal() {
      if (this.$attrs.width) this.width = this.$attrs.width;

      this.padding = false;
      this.margin = false;
    },

    fetchSyndicates() {
      axios.get('/api/doubts/sindicatos/doubts').then(({ data }) => {
        data.data.forEach(syndicate => {
          this.options.push({
            name: syndicate.name,
            url: syndicate.url
          });
        });
      });
    }
  },

  mount() {
    this.show();
  },
  created() {
    this.setModal();
    this.fetchSyndicates();
  }
};
</script>
<style>
.main-vue-modal {
  padding: 32px 16px;
}

.no-padding {
  padding: 0;
}

.rounded-3xl {
  border-radius: 1.5rem !important;
}

.vue-modal-header {
  font-size: 32px;
  text-align: center;
  margin-bottom: 16px;
}

.vue-modal-body {
  font-size: 16px;
  text-align: center;
  margin-bottom: 16px;
}

.no-margin {
  margin: 0;
}

.close {
  position: absolute;
  display: block;
  right: 0;
  top: -10px;
}
</style>
