<template>
  <app-layout>
    <div class="flex flex-col rounded-lg ">
      <div class="w-full flex flex-col md:w-10/12 md:mx-auto rounded-lg bg-white bg-opacity-90 shadow-2xl shadow-black">
        <h1 class="flex text-4xl justify-center xl:text-3xl bold text-green-1003 mt-4">
        Dúvidas Frequentes
      </h1>
        <section class="text-green-1002">
          <div class="flex flex-col m-4 flex-1">
            <div class="lg:flex justify-between px-2">
              <div class="items-start justify-between md:w-96">
                <label for="" class="mt-4">Filtrar:</label>
                <input type="text"
                  class=" block bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 w-full leading-tight focus:outline-none focus:bg-white"
                  v-model="filterWord" placeholder="Pesquise sua dúvida aqui...">
              </div>
              <section class="flex justify-end">
                <button class="mt-4 flex items-center" @click="SelecionarSindicato()">
                  Não encontrou o que procura? Clique aqui e entre em contato com o seu sindicato rural.
                  <figure class="w-16 h-16 ml-2 flex items-center">
                    <img
                      src="https://sistemafamato.org.br/sindicatos-rurais/wp-content/themes/sindicatos-rurais/assets/images/logo.jpg"
                      alt="Minha Figura">
                  </figure>
                </button>
              </section>
            </div>
            <div>
              <div class="toggle bg-gray-100" v-for="topic in filteredTopics" :key="topic.id">
                <button type="button" class="toggle-title text-left w-full cursor-pointer" @click="disparo">
                  <h3 class="title-name p-4 w-11/12 text-2xl font-bold">{{ topic.title }}<i class="ri-arrow-right-s-fill"></i></h3>
                </button>
                <div class="toggle-inner rounded-b-2xl bg-gray-100">
                  <h4 class="text-xl mt-2 leading-10" v-html="topic.description"></h4>
                  <div class="w-full mt-4 border-t-2 border-gray-200 border-opacity-60"></div>
                  <div class="flex flex-col items-center mt-2">
                    <h3 class="text-center">Continua com dúvidas ou para mais informações clique no botão abaixo e entre em contato com o seu Sindicato Rural.</h3>
                  <button class="flex mx-auto mt-2 text-white bg-green-600 border-0 py-2 px-3 focus:outline-none hover:bg-green-700 rounded text-lg" @click="SelecionarSindicato()">
                    Encontre seu Sindicato Rural
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <modal-layout :width="'90%'"></modal-layout>
  </app-layout>
</template>

<script>
import AppLayout from '../layouts/AppLayout';
import axios from '@/utils/axios';
import ModalLayout from '../components/ModalSyndicate';

export default {
  name: 'Validation',

  title() {
    return `${process.env.VUE_APP_NAME} | Recrutador - Validação`;
  },

  components: {
    AppLayout,
    ModalLayout
  },

  data() {
    return {
      topics: [],
      filterWord: ""
    }
  },

  computed: {
    filteredTopics() {
      var fw = this.filterWord.toLowerCase();

      // return topic based on a condition
      return this.topics.filter(topic => {
        // return all topics if there's no filter word
        if (fw == "") {
          return topic;
        } else {
          // return topic if filter word is found in title or description
          if (topic.title.toLowerCase().indexOf(fw) != -1 || topic.description.toLowerCase().indexOf(fw) != -1) {
            return topic;
          }
        }
      });
    }
  },
  methods: {
    fetchdoubts() {
      axios
        .get('api/doubts/all').then(response => {
          response.data.data.forEach(doubs => {
            this.topics.push({
              title: doubs.title,
              description: doubs.description
            });
          });
        });
    },

    SelecionarSindicato() {
      this.$modal.show('modal');
    },


    disparo: function (event) {
      const el = event.target

      const toggle = el.closest('.toggle')
      const description = toggle.querySelector('.toggle-inner')

      if (el.classList.contains('active')) {
        el.classList.remove('active');
        description.style.display = 'none';
      } else {
        el.classList.add('active');
        description.style.display = 'block';
      }
    }
  },

  async created() {
    this.fetchdoubts();
  }
};
</script>

<style scoped>
.toggle {
  background-color: #fff;
  border-radius: 16px;
  line-height: 1.8em;
  overflow: hidden;
  margin: 15px 0;
  border: 1px solid #e9eaed;
}

.toggle:hover {
  -webkit-box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.15);
}

.toggle .toggle-title {
  display: block;
  position: relative;
  opacity: .75;
}

.toggle .toggle-title.active {
  opacity: 1;
}

.toggle .toggle-title h3 {
  margin: 0px;
  cursor: pointer;
}

.toggle .toggle-inner {
  display: none;
  padding: 7px 25px 10px 25px;
}

.toggle .toggle-inner div {
  max-width: 100%;
}

.toggle .toggle-title .title-name {
  display: block;
}

.toggle .toggle-title .active {
  border-bottom: 1px solid #e9eaed;
}

.toggle .toggle-title a i {
  margin-right: 5px;
}

.toggle .toggle-title i {
  position: absolute;
  width: 24px;
  height: 24px;
  transition: all 0.3s ease;
  right: 25px;
  top: 15px;
  border-bottom: 0px solid #e9eaed;
}

.toggle .toggle-title .title-name i:before {
  transition-duration: 75ms;
  display: block;
}

.toggle .toggle-title .title-name.active i:before {
  transform: rotate(90deg);
  transition-duration: 75ms;
}
</style>
