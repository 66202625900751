<template>
  <app-layout>
    <div class="flex justify-center">
      <div
        class="mx-5 px-2 mt-10 pb-2 bg-white bg-opacity-90 rounded-lg sm:mb-5 sm:w-8/12 md:w-8/12 md:mt-16"
      >
        <h1 class="text-4xl xl:text-6xl bold text-green-1003 mb-6">
          COLHEITA <br />DE TALENTOS
        </h1>
        <p class="mb-6 text-lg xl:text-3xl leading-6 text-justify md:text-2xl">
          O <span class="bold">Senar Mato Grosso</span> e os
          <span class="bold">Sindicatos Rurais</span> contribuem para a formação
          de muitos profissionais
          <span class="bold">que vão atuar no agro</span>, profissionais que
          podem fazer a diferença na colheita de grandes resultados.
        </p>
        <p class="text-lg xl:text-3xl leading-6 text-justify md:text-2xl">
          Por isso juntos, <span class="bold">Senar Mato Grosso</span> e
          <span>Sindicatos Rurais</span>, criamos uma
          <span class="bold"
            >plataforma com mais de 200.000 currículos para vocês</span
          >, empresas do agro e produtores rurais, contratarem os profissionais
          mais qualificados e colher produtividade e crescimento.
        </p>
      </div>
    </div>
  </app-layout>
</template>

<script>
import AppLayout from '../layouts/AppLayout';

export default {
  name: 'Home',

  title() {
    return `${process.env.VUE_APP_NAME}`;
  },

  components: {
    AppLayout
  }
};
</script>

<style scoped></style>
